import React, { FC, useContext, useEffect } from 'react'
import { NavigationContext } from '@/contexts/NavigationContext'
import StoresWrapper from '@/containers/StoresWrapper'
import StoresIndex from '@/components/StoreIndex'
import { StoresContext } from '@/contexts/StoresContext'
import { graphql } from 'gatsby'
import Head from '@/containers/Head'

const Stores: FC = (props) => {
  const { setBackButton } = useContext(NavigationContext)
  const { setCenter, setZoom } = useContext(StoresContext)

  useEffect(() => {
    setBackButton(false)
    setCenter({
      lat: 47.375,
      lng: 8.53
    })
    setZoom(13)
  }, [])

  const {title, meta} = props.data.sanityPage

  return <>
    <Head title={title} meta={meta} />
    <StoresIndex />
  </>
}

Stores.StoresWrapper = StoresWrapper
Stores.isStoreIndex = true

export default Stores

export const query = graphql`
  query {
    sanityPage(title: { eq: "Stores" }) {
      id
      title
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
